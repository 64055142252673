// begin cookies
/* function cookies() {
  if (!localStorage.getItem("cookiesAccepted")) {
    var cookieMessage = document.getElementById("cookie-notification");
    var closeCookie = document.getElementById("cookie-notification-close");

    cookieMessage.style.display = "flex";
    closeCookie.addEventListener("click", function (e) {
      e.preventDefault();
      localStorage.setItem("cookiesAccepted", true);

      cookieMessage.style.display = "none";
    });
  }
}
cookies(); */
// end cookies

// begin alert popup
var el = document.getElementById("alert-pup");

function alertPup(title, message, classMessage) {
  el.animate(
    [{ tranfsform: "tralateY(0px)" }, { tranfsform: "tralateX(-300px)" }],
    {
      duration: 3000,
      iteration: 1,
    }
  );

  el.innerHTML = `
    <div class="toast ${classMessage}">
      <div>
        <h2>${title}</h2>
        <p>${message}</p>
      </div>
    </div>`;

  setTimeout(() => cleanPup(), 6000);
}

function cleanPup() {
  el.innerHTML = "";
}
// end alert popup

// begin contact form
function contactForm() {
  //
  function setSpanError(bool, elInput, elErrorSpan) {
    if (bool) {
      elInput.classList.add("form-error-message");
      elErrorSpan.classList.add("active");
    } else {
      elInput.classList.remove("form-error-message");
      elErrorSpan.classList.remove("active");
    }
  }

  // begin input função condominio
  const fuctionInput = document.getElementById("contact-fuction");
  const fuctionErrorSpan = document.getElementById("error-input-function");

  function isInvalidFunction() {
    if (fuctionInput.value == "" || fuctionInput.value == null) {
      return true;
    } else {
      return false;
    }
  }
  // end input função condominio

  // begin input name
  const nameInput = document.getElementById("contact-name");
  const nameErrorSpan = document.getElementById("error-input-name");

  function isInvalidName() {
    const nameValidator = /[A-Za-z]{3}/;
    let isNameValid = nameValidator.test(nameInput.value);

    if (isNameValid) {
      return false;
    } else {
      return true;
    }
  }
  // end input name

  // begin input email
  const emailInput = document.getElementById("contact-mail");
  const emailErrorSpan = document.getElementById("error-input-email");

  function isInvalidEmail() {
    const emailValidator =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isEmailValid = emailValidator.test(emailInput.value.toLowerCase());

    if (isEmailValid) {
      return false;
    } else {
      return true;
    }
  }
  // end input email

  // begin mensagem
  const messageInput = document.getElementById("contact-mensage");
  const messageErrorSpan = document.getElementById("error-input-mensage");

  function isInvalidMessage() {
    if (messageInput.value == null || messageInput.value == "") {
      return true;
    } else {
      return false;
    }
  }
  // end mensagem

  // begin input telefone
  const phoneInput = document.getElementById("contact-phone");
  const phoneErrorSpan = document.getElementById("error-input-phone");

  function isInvalidPhoneNumeber() {
    const phoneValidator =
      /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
    let isPhoneNumerValid = phoneValidator.test(phoneInput.value.toLowerCase());

    if (isPhoneNumerValid) {
      return false;
    } else {
      return true;
    }
  }

  function maskPhoneInput() {
    let inputValue = phoneInput.value;

    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/^(\d{2})(\d)/g, "($1) $2");
    inputValue = inputValue.replace(/(\d)(\d{4})$/, "$1-$2");

    phoneInput.value = inputValue;
  }

  phoneInput.oninput = maskPhoneInput;
  // end input telefone

  //
  function handleSpanError() {
    setSpanError(isInvalidName(), nameInput, nameErrorSpan);
    setSpanError(isInvalidEmail(), emailInput, emailErrorSpan);
    setSpanError(isInvalidPhoneNumeber(), phoneInput, phoneErrorSpan);
    setSpanError(isInvalidFunction(), fuctionInput, fuctionErrorSpan);
    setSpanError(isInvalidMessage(), messageInput, messageErrorSpan);
  }

  //
  function validateInputFromForm() {
    if (
      isInvalidName() ||
      isInvalidEmail() ||
      isInvalidPhoneNumeber() ||
      isInvalidFunction() ||
      isInvalidMessage()
    ) {
      return false;
    } else {
      return true;
    }
  }

  // begin action send message
  function sendMessage(e) {
    e.preventDefault();
    handleSpanError();
    if (validateInputFromForm())
      sendEmail();
  }
  
  function startLoadingOnButton(btn) {
    btn.disabled = true;
    btn.classList.add('fa-fade');
    btn.innerText = 'Enviando...'
  } 

  function endLoadingOnButton(btn) {
    btn.classList.remove('fa-fade');
    btn.innerText = 'Enviado!'
  }

  const buttonSend = document.querySelector(".button-send");
  buttonSend.onclick = sendMessage;
  // end action send message

  // TODO: 3- validar retorno do envio de email
  async function sendEmail(e) {
    //
    if (e && e instanceof Event) {
      e.preventDefault();
    }

    const functions = firebase.functions();
    // functions.useEmulator("localhost", 5001);
    var sendMessage = functions.httpsCallable("sendMessage");
    var sendGoogleSheet = functions.httpsCallable("sendGoogleSheet");

    const _data = {
      name: nameInput.value,
      email: emailInput.value,
      phone: phoneInput.value,
      fuction: fuctionInput.value,
      message: messageInput.value
    };

    // handle data message

    // messageInput && messageInput.value
    //   ? (_data.message = `Telefone para contato: ${phoneInput.value}. Mensagem: ${messageInput.value}.`)
    //   : (_data.message = "Telefone para contato: " + phoneInput.value + ".");

    // _data.message = _data.message + ":" + fuctionInput.value;
    startLoadingOnButton(buttonSend)

    grecaptcha.ready(() =>
      grecaptcha
        .execute("6LfvwakUAAAAALqjEOAZYcelk24esaEdwaAJ-CRO", {
          action: "submit",
        })
        .then((token) => { 
          _data.token = token;

          // action sendMessage
          sendMessage({ ..._data })
            .then((response) => {
              window.location.href =
              "https://zipcondominio.com.br/obrigado";
              // TODO: cleanFormContact();
            })
            .catch((error) => {
              alertPup(
                "Erro",
                `Houve um erro no envio da mensagem. 
                            Tente novamente mais tarde, ou nos contate por nossas redes sociais!`,
                "pup-error"
              );
            })
            .finally(() => {
              endLoadingOnButton(buttonSend);
            });

            sendGoogleSheet({ ..._data })
            .then((response) => {
                console.log("response googleSheet", response);
            })
            .catch((error) =>
                console.log("error sendGoogleSheet", error)
            );
        })
        .catch((err) => {
          // Promise.reject(err)
          // console.log(`ERROR 4323 ::: ${err}`);
        })
    );

    return;
    // TODO: tratar retorno do erro
    /* if(error) {
          return changePopupText(`Houve um erro no envio da mensagem. 
            Tente novamente mais tarde, ou nos contate por nossas redes sociais!`); 
            canDeactivatePopup = true; 
      } */
  }
}

contactForm();
// end contact form

// begin faq section
function faqSection() {
  const faqList = document.querySelectorAll(".faq-list");
  function activeItem(el) {
    if (el.classList.contains("active")) {
      el.classList.remove("active");
    } else {
      el.classList.add("active");
    }
  }

  faqList.forEach((el) => (el.onclick = () => activeItem(el)));
}

faqSection();
// end faq section

// begin something
function handleNavBarClose(){
  // const menu = document.querySelector("#menu");
  // const navbar = document.querySelector(".menu-list-dropdown");  
  // console.log("menu: ",menu);

  // menu.addEventListener('change', function() {
  //   if (this.checked) {

  //   } else {
  //     console.log('O checkbox não está marcado.');
  //   }
  // });


//   document.addEventListener('click', function() {
//   if (window.getComputedStyle(navbar).display === "none") {
//     console.log("O elemento está oculto.");
// } else {
//     menu.checked = false; 
//     console.log("O elemento não está oculto.");
//   }
// })


  // document.addEventListener("click", function(event) {
  //   if (event.target.closest(navbar) === null) {
  //     dropdownList.style.display = "none";
  //   }
  // });


  var navMenu = document.querySelector("#menu");
  var dropdownList = document.querySelector(".menu-list-dropdown");
  
  navMenu.addEventListener("click", function(event) {
    dropdownList.style.display = "block";
    event.stopPropagation(); // Impede que o clique se propague para o documento
  });
  
  document.addEventListener("click", function(event) {
    if (event.target.closest(".menu-list-dropdown") === null) {
      dropdownList.style.display = "none";
    }
  });
  
  dropdownList.addEventListener("click", function(event) {
    dropdownList.style.display = "none";
    event.stopPropagation();
  });

  
}
handleNavBarClose()
//end something
